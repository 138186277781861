.cws-badge {
  width: 25%;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.canvas-fixer-gif {
  padding-top: 5px;
  padding-bottom: 15px;
}

.canvas-fixer-faq {
  padding-top: 20px;
}